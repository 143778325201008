import React from 'react';
import PropTypes from 'prop-types';

const PinraTableRow = ({ row }) => {
  return (
    <tr>
      <td className="col-md-3">{row.activity_name}</td>
      <td className="col-md-2">{row.date_times_attending}</td>
      <td className="col-md-3">{row.activity_theme}</td>
      <td className="col-md-1">{row.ceus_to_be_awarded}</td>
      {(() => {
        if (row.status === '1')
          return (
            <td className="col-md-3">
              <span className="fa fa-clock-o icon-space-r" /> Waiting on sponsor approval to attend
              event
            </td>
          );
        if (row.status === '2')
          return (
            <td className="col-md-3">
              <span className="fa fa-clock-o icon-space-r" /> Waiting for you to attend your event
            </td>
          );
        if (row.status === '3')
          return (
            <td className="col-md-3">
              <span className="fa fa-clock-o icon-space-r" /> Waiting for you to upload documents
            </td>
          );
        if (row.status === '4')
          return (
            <td className="col-md-3">
              <span className="fa fa-clock-o icon-space-r" /> Waiting for sponsor final approval
            </td>
          );
        if (row.status === '5')
          return (
            <td title="Complete" className="col-md-3">
              <span className="fa fa-check" /> Complete
            </td>
          );
        if (row.status === '6')
          return (
            <td title={row.withdraw_reason} className="col-md-3">
              <span className="fa fa-times-circle" /> Incomplete - {row.withdraw_reason}
            </td>
          );
      })()}
    </tr>
  );
};

PinraTableRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default PinraTableRow;
