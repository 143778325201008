import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EventDictionary } from 'helpers/dictionaries';

const { REG_STATUS } = EventDictionary;

const yes = <span className="fa fa-check" />;
const no = <span className="fa fa-minus" />;

const statusTipStyle = {
  paddingLeft: 8,
  paddingRight: 8,
  paddingBottom: 2,
};

const StatusCell = ({ completed, tipComplete, tipIncomplete }) => {
  const className = completed ? 'success' : 'danger';
  const tip = completed ? tipComplete : tipIncomplete;
  return (
    <td className={`center ${className}`}>
      <span data-tip={tip} style={statusTipStyle}>
        {completed ? yes : no}
      </span>
    </td>
  );
};

StatusCell.propTypes = {
  completed: PropTypes.any,
  tipComplete: PropTypes.string.isRequired,
  tipIncomplete: PropTypes.string.isRequired,
};

const ActivityReportCells = ({ row, userSlug }) => {
  if (row.canceled) {
    return <td colSpan="3" />;
  }
  const withdrawn = row.registration_status === REG_STATUS.WITHDRAW;
  if (withdrawn) {
    return (
      <td colSpan="3" className="center">
        <span className="label label-default">Withdrawn</span>
      </td>
    );
  }
  if (!row.ended) {
    return (
      <td colSpan="3" className="center">
        <small>
          <em>
            <span className="fa fa-clock-o icon-space-r" />
            Available after event has ended
          </em>
        </small>
      </td>
    );
  }
  if (row.completed) {
    return (
      <td colSpan="3" className="success center">
        <a
          href={window.URLS['pd:certificate_export'](row.event_id, userSlug)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-file-pdf-o icon-space-r" />
          View Certificate
        </a>
      </td>
    );
  }
  return (
    <React.Fragment>
      <StatusCell
        completed={row.attended}
        tipComplete="The facilitator(s) confirmed your participation"
        tipIncomplete="The facilitator(s) have not confirmed your participation"
      />
      <StatusCell
        completed={row.evaluated}
        tipComplete="You completed the evaluation"
        tipIncomplete="You have not completed the evaluation"
      />
      <StatusCell
        completed={row.signed}
        tipComplete="You signed the activity report"
        tipIncomplete="You have not signed the activity report"
      />
    </React.Fragment>
  );
};

ActivityReportCells.propTypes = {
  row: PropTypes.object.isRequired,
  userSlug: PropTypes.string.isRequired,
};

// eslint-disable-next-line eqeqeq
const NumericCell = ({ value }) => <td>{!value || value == 0 ? <span>&mdash;</span> : value}</td>;

NumericCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const TranscriptTableRow = ({ row, userSlug }) => {
  return (
    <tr>
      <td>
        <em>{row.event_type}</em>
        <br />
        {row.strand}
      </td>
      <td>
        <Link to={window.URLS['pd:event_detail'](row.event_id)}>{row.event}</Link>
      </td>
      <td>{row.ceu_type}</td>
      <NumericCell value={row.ceus} />
      <NumericCell value={row.total_hours} />
      <ActivityReportCells row={row} userSlug={userSlug} />
    </tr>
  );
};

TranscriptTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  userSlug: PropTypes.string.isRequired,
};

export default TranscriptTableRow;
