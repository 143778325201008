import React from 'react';
import PropTypes from 'prop-types';
import { EventDictionary } from 'helpers/dictionaries';
import TranscriptTableRow from './TranscriptTableRow';

const { REG_STATUS } = EventDictionary;

const groupData = (data) => {
  const groups = {
    Completed: { data: [], totals: null },
    Facilitated: { data: [], totals: null },
    Attempted: { data: [], totals: null },
    Pending: { data: [], totals: null },
    Canceled: { data: [], totals: null },
  };
  data.forEach((item) => {
    if (item.canceled) {
      groups.Canceled.data.push(item);
    } else if (item.registration_status === REG_STATUS.WITHDRAW) {
      groups.Attempted.data.push({
        ...item,
        ceus: 0,
        total_hours: 0,
      });
    } else if (item.registration_status === REG_STATUS.FACILITATOR) {
      groups.Facilitated.data.push(item);
    } else if (!item.ended) {
      groups.Pending.data.push(item);
    } else if (item.ended && item.completed) {
      groups.Completed.data.push(item);
    } else if (item.ended && !item.completed) {
      groups.Attempted.data.push(item);
    }
  });
  Object.keys(groups).forEach((key) => {
    groups[key].totals = groups[key].data.reduce(
      (accum, event) => {
        accum.ceus += parseFloat(event.ceus) || 0.0;
        accum.hours += parseFloat(event.total_hours) || 0.0;
        return accum;
      },
      { ceus: 0.0, hours: 0.0 }
    );
  });
  return groups;
};

const sortByCeusAndHourValue = function(row) {
  let value = row.total_hours;
  if (row.registration_status === REG_STATUS.WITHDRAW) {
    value -= 10000;
  }
  if (row.ceu_type === 'None') {
    value -= 1000;
  }
  return value;
};

const sortByCeusAndHours = function(a, b) {
  // Sort such that:
  //  1. Withdrawn events get sorted to the bottom
  //  2. Events without CEUs get sorted to the bottom
  //  3. Events are then sorted by # of total hours
  const aValue = sortByCeusAndHourValue(a);
  const bValue = sortByCeusAndHourValue(b);
  return parseFloat(bValue) - parseFloat(aValue);
};

const TranscriptTable = ({ data, year, userSlug }) => {
  const groups = groupData(data);
  return Object.entries(groups).map(([group, { data, totals }]) =>
    data.length ? (
      <table className="table table-condensed" key={`${group}-${year}`}>
        <thead>
          <tr>
            <th colSpan="9" style={{ borderBottom: 'none' }}>
              {group}
            </th>
          </tr>
          <tr>
            <th>
              <strong>
                <em>Event Type</em>
              </strong>
              <br />
              Strand
            </th>
            <th>Event</th>
            <th>CEU Type</th>
            <th>CEUs</th>
            <th>Total Hours</th>
            <th>Participated</th>
            <th>Evaluation</th>
            <th>
              Signed
              <br />
              Report
            </th>
          </tr>
        </thead>
        <tbody>
          {data.sort(sortByCeusAndHours).map((item) => (
            <TranscriptTableRow key={item.id} row={item} userSlug={userSlug} />
          ))}
        </tbody>
        <thead>
          <tr className="active">
            <th colSpan="3">Totals</th>
            <th>{totals.ceus.toFixed(2)}</th>
            <th>{totals.hours.toFixed(2)}</th>
            <th />
            <th />
            <th />
          </tr>
        </thead>
      </table>
    ) : null
  );
};

TranscriptTable.propTypes = {
  data: PropTypes.array.isRequired,
  userSlug: PropTypes.string.isRequired,
};

export default TranscriptTable;
