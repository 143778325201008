import React from 'react';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import ReactTooltip from 'react-tooltip';

import { Transcript, PinraEvents } from 'api';
import { PageHeader } from 'components';
import { EventDictionary } from 'helpers/dictionaries';
import TranscriptTable from './TranscriptTable';
import PinraTableRow from './PinraTableRow';

const SEASONS = Object.values(EventDictionary.SEASON_TYPES);

const sortByYear = function(a, b) {
  // a and b are from Object.entries; the first entry is the key
  // keys come in the form of "2015-2016" -- we sort based on the first year
  const aYear = parseInt(a[0].split('-')[0]);
  const bYear = parseInt(b[0].split('-')[0]);
  return bYear - aYear;
};

class PdTranscriptPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
  };

  state = {
    transcript: null,
    pinras: null,
  };

  async componentDidMount() {
    const transcript = await Transcript.get(this.props.match.params.userSlug);
    const pinras = await PinraEvents.listTranscript(this.props.match.params.userSlug);
    this.setState({ transcript, pinras });
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  toggleItem = (e) => {
    const isActive = e.target.classList.contains('active');
    if (isActive) {
      e.target.classList.remove('active');
      e.target.parentElement.classList.remove('active');
    } else {
      e.target.classList.add('active');
      e.target.parentElement.classList.add('active');
    }
  };

  render() {
    const { transcript, pinras } = this.state;
    const isLoading = !transcript;
    const years = !isLoading && Object.entries(transcript).sort(sortByYear);
    const sortedPinras = !isLoading && this.state.pinras.sort((a, b) => b.id - a.id);
    const hasRegistrations = years && !!years.length;
    const userSlug = this.props.match.params.userSlug;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12">
            <div className="white-soft-box2 white-soft-box2--no-animate">
              <PageHeader.Basic title="PD Transcript" subtitle={userSlug} />
              {!isLoading && pinras.length !== 0 && (
                <ul className="nav accordion">
                  <h3>PINRA's</h3>
                  <li className="search-item">
                    <a className="search-text" onClick={this.toggleItem}>
                      <i className="fa fa-fw fa-angle-right" />
                      <i className="fa fa-fw fa-angle-down" />
                      Click to see your PINRA's
                    </a>
                    <div style={{ marginBottom: 15 }}>
                      <table className="table table-condensed">
                        <thead>
                          <tr>
                            <th className="col-md-3">Event Name</th>
                            <th className="col-md-2">Event Date</th>
                            <th className="col-md-3">Event Theme</th>
                            <th className="col-md-1">CEU's</th>
                            <th className="col-md-3">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedPinras.map((pinra) => (
                            <PinraTableRow key={pinra.id} row={pinra} userSlug={userSlug} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              )}
              {!isLoading && !hasRegistrations && (
                <p className="mt1">
                  No Professional Development events have been attempted.{' '}
                  <Link to={window.URLS['pd:event_explore']()}>Explore</Link> upcoming events.
                </p>
              )}
            </div>
          </div>
        </div>
        {!isLoading &&
          hasRegistrations &&
          years.map(([year, seasons]) => (
            <div className="row" key={year}>
              <div className="col-xs-12">
                <div className="white-soft-box2 white-soft-box2--no-animate">
                  <PageHeader.Basic title={year} subtitle="Academic Year" />
                  <div className="mt1" />
                  {SEASONS.map((seasonTitle) => {
                    const seasonKey = seasonTitle.toLowerCase();
                    return (
                      <React.Fragment key={`${year}-${seasonKey}`}>
                        <h3 className="center section-header mt1">{seasonTitle}</h3>
                        {seasons[seasonKey] && seasons[seasonKey].length ? (
                          <TranscriptTable
                            year={`${year}-${seasonKey}`}
                            data={seasons[seasonKey]}
                            userSlug={userSlug}
                          />
                        ) : (
                          <p>No professional development attempted.</p>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        <ReactTooltip
          className="events__tooltip"
          effect="solid"
          insecure
          multiline
          place="bottom"
          type="custom"
        />
      </React.Fragment>
    );
  }
}

export default PdTranscriptPage;
